import React from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import Head from 'next/head';

export default function Layout({ children, title, description, keywords, url, image, canonical }) {
  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta name="theme-color" content="#000000" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="language" content="it" />
        <meta name="robots" content="index, follow" />
        <meta name="googlebot" content="index, follow" />
        <link rel="icon" href="/favicon.png" />
        <link rel="canonical" href={canonical} />
        <meta property="og:locale" content="it_IT" />
        <meta property="og:locale:alternate" content="it" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="og:site_name" content="Tokkiro" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@tuUsuarioTwitter" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Product",
            "name": title,
            "image": [image],
            "description": description,
            "offers": {
              "@type": "Offer",
              "priceCurrency": "EUR",
            }
          })}
        </script>
      </Head>

      <Header />
      <main style={{ minHeight: 'calc(100vh - 80px)' }}>
        {children}
      </main>

      <Footer />
    </>
  );
}

import React, { useState, useRef, useContext, useEffect } from 'react';
import styles from './styles/Header.module.scss';
import { useRouter } from 'next/router';
import { IoMenu } from 'react-icons/io5';
import NavMobile from './NavMobile';
import Nav from './Nav';
import { ThemeContext } from '@/hooks/ThemeContext';
import ThemeToggleButton from './ToggleTheme';
import Logo from './Logo';

export default function Header() {
    const [menuMobile, setMenuMobile] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const overlayRef = useRef(null);
    const router = useRouter();
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        const onScroll = () => {
            if (window.scrollY > 100) {
                setScrolled(true);
                document.body.classList.add('scrolled');
            } else {
                setScrolled(false);
                document.body.classList.remove('scrolled');
            }
        };

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (overlayRef.current && !overlayRef.current.contains(event.target)) {
                setMenuMobile(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <>
            {(scrolled || menuMobile) && (
                <header className={`${styles.headerFixed} ${styles.scrolled}`} style={{ backgroundColor: theme.header }}>
                        <Logo mode={scrolled} />
                </header>
            )}

            {(!scrolled || !menuMobile) && (
                <header className={styles.header} style={{ backgroundColor: theme.header }}>
                    <div className={styles.content}>
                        <div className={styles.header__logo} onClick={() => router.push('/')}>
                            <Logo mode={scrolled} />
                        </div>
                        <div className={styles.themeToggleButton}>
                            <ThemeToggleButton />
                        </div>

                        <Nav />

                        <button
                            className={styles.menuMobile}
                            onClick={() => setMenuMobile(!menuMobile)}
                        >
                            <IoMenu size={30} color={theme === 'dark' ? 'white' : 'black'} />
                        </button>

                        {menuMobile && (
                            <NavMobile overlayRef={overlayRef} setMenuMobile={setMenuMobile} />
                        )}
                    </div>
                </header>
            )}
        </>
    );
}

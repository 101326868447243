import React, { useState } from 'react';
import styles from './styles/Nav.module.scss';
import Link from 'next/link';
import { Links } from '../assets/navigation/navigation';
import { useRouter } from 'next/router';

export default function Nav() {
    const [isActive, setIsActive] = useState(false);
    const router = useRouter();


    const pathname = router.pathname;


    return (
        <nav className={styles.nav}>
            {Links.map((link, index) => (
                <ul className={styles.nav__list} key={index}>
                    <li className={`${styles.nav__item} ${ pathname === link.url ? styles.active : '' }`}>
                        <Link href={link.url} aria-label={`Vai a ${link.name}`} rel='preload'>{link.name}</Link>
                    </li>
                </ul>
            ))}
        </nav>
    );
}

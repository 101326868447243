import React from 'react';
import { gql, useQuery } from '@apollo/client';
import {GET_PRODUCTSLASTESTBYCATEGORYTONUMBER } from '@/pages/api/Products';
import style from './BannerGaming.module.scss';
import Image from 'next/image';
import Link from 'next/link';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function BannerGaming() {
    const category = 'gaming'; 
    const { data, loading, error } = useQuery(GET_PRODUCTSLASTESTBYCATEGORYTONUMBER, {
        variables: {
            category: category,
            number: 6
        }
    });

    if (loading) {
        return (
            <SkeletonTheme color="#e0e0e0" enableAnimation={true} baseColor='#f3f3f3' highlightColor='#f5f5f5' >
                <div className={style.main}>
                    <ul className={style.list}>
                        {[...Array(5)].map((_, index) => (
                            <li key={index} className={style.item}>
                                <div className={style.image}>
                                    <Skeleton height={500} count={20} />
                                </div>
                                <div className={style.content}>
                                    <h2><Skeleton width={200} /></h2>
                                    <p><Skeleton count={20} /></p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </SkeletonTheme>
        );
    }

    if (error) return <div>Error: {error.message}</div>;
    if (!data) return <div>No data</div>;
    const products = data.getProductsByLastCategoryToNumber || [];
    return (
        <div className={style.main}>
            <ul className={style.list}>
                {products.map(product => (
                    <li key={product.id} className={style.item}>
                        <Link href={`/${category}/${product.slug}`} aria-label={product.title} title={product.title}>
                                <div className={style.image}>
                                    <Image
                                        src={product.image}
                                        alt={product.title}
                                        width={500}
                                        height={400}
                                        loading="lazy"
                                        className={style.productImage}
                                        sizes="(max-width: 500px) 100vw, 500px"
                                    />
                                </div>
                                <div className={style.content}>
                                    <h2>{product.title}</h2>
                                    <div dangerouslySetInnerHTML={{ __html: product.subDescription }} />
                                </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

import React from 'react'
import styles from './styles/Footer.module.scss'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { social } from '../assets/socials/social'

export default function Footer() { 
  const router = useRouter();
  return (
    <footer className={styles.footer}>
        <div className={styles.footer__logo}>
            <h2  onClick={() => router.push('/')} >
                Tokkiro
            </h2>
            <div className={styles.footer__logo__social}>
              {social.map((social, index) => (
                <div className={styles.footer__logo__social__item} key={index} onClick={() => router.push(social.url)}>
                    {social.image}
                </div>
              ))}
          </div>
        </div>
              <div className={styles.footer__links}>
                <p>
                  Tutti i diritti riservati {new Date().getFullYear()}
                </p>
              </div>
    </footer> 
  )

  }
import React, {useState} from 'react'
import css from './styles/Logo.module.scss'
export default function Logo( {mode} ) {
    

    const [Logo, setLogo] = useState(null);
    
    console.log(mode)
    
  return (
    <div >
        <h1 className={`${mode === true ? css.logoTextScroll : css.logoText}`}>
            Tokkiro
        </h1>
    </div>
  )
}

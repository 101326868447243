import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { GET_PRODUCTSBYCATEGORY } from '@/pages/api/Products';
import style from './BannerModa.module.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from 'next/image';
import Link from 'next/link';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

export default function BannerModa() {
    const category = 'moda'; 
    const { data, loading, error } = useQuery(GET_PRODUCTSBYCATEGORY, {
        variables: {
            category: category
        }
    });

    return (
        <div className={style.moda}>
            {loading ? (
                <SkeletonTheme color="#e0e0e0" enableAnimation={true} baseColor='#f3f3f3' highlightColor='#f5f5f5' >
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        navigation
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            320: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20
                            },
                            1024: {
                                slidesPerView: 3,
                                spaceBetween: 50
                            }
                        }}
                    >
                        {[...Array(3)].map((_, index) => (
                            <SwiperSlide key={index} className={style.swiper__slide}>
                                <div className={style.slide}>
                                    <Skeleton height={500} 
                                        count={20}
                                    />
                                    <div className={style.content}>
                                        <h2><Skeleton width={200} /></h2>
                                        <p><Skeleton count={2} /></p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </SkeletonTheme>
            ) : (
                <Swiper
                    spaceBetween={50}
                    slidesPerView={3}
                    navigation
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false
                    }}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        640: {
                            slidesPerView: 1,
                            spaceBetween: 20
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 50
                        }
                    }}
                >
                    {data && data.getProductsByCategory.map(product => (
                        <SwiperSlide key={product.id} className={style.swiper__slide}>
                        <Link href={`/${product.category}/${product.slug}`} className={style.link} target="_blank" aria-label={product.title} rel='noopener noreferrer' >
                                <div className={style.slide}>
                                <Image 
                                    src={product.image} 
                                    alt={product.title} 
                                    width={500} 
                                    height={500} 
                                    loading="eager" 
                                    decoding="async" 
                                    loop={true}
                                />
                                    <div className={style.content}>
                                        <h2>{product.title}</h2>
                                        <p>{product.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </SwiperSlide>
                    ))}
                </Swiper>
            )}
        </div>
    );
}

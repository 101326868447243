import React, { useContext } from 'react';
import { ThemeContext } from '@/hooks/ThemeContext'; 
import styles from './styles/ToggleTheme.module.scss';
import { GiNightSky } from "react-icons/gi";
import { RiFireLine } from "react-icons/ri";

export default function ThemeToggleButton() {
    const { theme, toggleTheme } = useContext(ThemeContext);

    const handleClick = () => {
        toggleTheme(); 
    };

    return (
        <div className={styles.toggleTheme}>
            <input type="checkbox" id="toggle" className={styles.toggle} onClick={handleClick} />
            <label htmlFor="toggle" className={styles.switch}>
                <div className={styles.iconWrapper}>
                    {theme === 'light' ?  <RiFireLine size={24} color="black"/> : <GiNightSky size={24} color='black' />}
                </div>
            </label>
        </div>
    );
}

import React from 'react';
import { gql, useQuery } from '@apollo/client';
import Image from 'next/image';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {GET_PRODUCTSLASTESTBYCATEGORYTONUMBER } from '@/pages/api/Products';
import { useRouter } from 'next/router';

import styles from './BannerTecnologia.module.scss';
import Link from 'next/link';

export default function BannerTecnologia() {
    const category = 'tecnologia';
    const router = useRouter();
    const { data, loading, error } = useQuery(GET_PRODUCTSLASTESTBYCATEGORYTONUMBER, {
        variables: {
            category: category,
            number: 5
        }
    });
    const products = data?.getProductsByLastCategoryToNumber || [];

    if (loading) {
        return (
            <SkeletonTheme color="#e0e0e0" highlightColor="#f5f5f5">
                <div className={styles.tecnologia}>
                    <div className={styles.container}>
                        {[1, 2, 3, 4].map(index => (
                            <article key={index} className={`${styles.product} ${index === 0 ? styles.large : ''}`}>
                                <div className={styles.product_image}>
                                    <Skeleton height={500} />
                                </div>
                                <div className={styles.product_description}>
                                    <Skeleton height={20} awidth={200} />
                                </div>
                            </article>
                        ))}
                    </div>
                </div>
            </SkeletonTheme>
        );
    }

    if (error) {
        return <div className={styles.error}>Error loading data</div>;
    }

    return (
        <div className={styles.tecnologia}>
            <div className={styles.container}>
                {products.map((product, index) => (
                    <Link key={index} href={`/${product.category}/${product.slug}`}className={`${styles.product} ${index === 0 ? styles.large : ''}`} aria-label={product.title} title={product.title} target='_blank' rel='noopener noreferrer'>
                    <article >
                        <div className={styles.product_image}>
                            <Image
                                src={product.image}
                                alt={product.name}
                                title={product.title}
                                width={500}
                                height={500}
                            />
                        </div>
                        <div className={styles.product_description}>
                            <h2>{product.title}</h2>
                        </div>
                    </article>
                    </Link>
                ))}
            </div>
        </div>
    );
}

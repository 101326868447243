    import styles from '@/styles/Home.module.scss'
import Layout from '@/components/Layout'
import dynamic from 'next/dynamic';
import BannerGaming from '@/components/banner/BannerGaming';
import BannerModa from '@/components/banner/BannerModa';
import BannerTecnologia from '@/components/banner/BannerTecnologia';

export default function Home() {
  const DynamiHomeProduct= dynamic(() => import('@/components/Home/HomeProductFitness'));

return (  
<Layout 
    title="Tokkiro News 2024: Moda, Fitness, Salute, Benessere e Gaming in Italia"
    description="Esplora le ultime tendenze della moda, ottieni consigli sulla salute e il fitness, scopri le novità nel mondo del benessere e immergiti nel mondo del gaming su Tokkiro News 2024!"
    keywords={["tokkiro", "tokkiro news 2024", "moda 2024", "fitness 2024", "salute 2024", "benessere 2024", "gaming 2024", "tendenze moda 2024", "consigli salute 2024", "novità gaming 2024", "news moda 2024", "news fitness 2024", "news salute 2024", "news benessere 2024", "news gaming 2024"]}
    url="https://www.tokkiro.com"
    canonicalUrl="https://www.tokkiro.com"
>
    <div className={styles.main}>
        <div className={styles.content}>
          <DynamiHomeProduct/>
          <BannerGaming/>
          <BannerTecnologia/>
          <BannerModa/>
        </div>
    </div>
    </Layout>
  )
}



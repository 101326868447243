import { FaSquareXTwitter } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";

export const social = [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/tokkiro',
      image: <FaFacebookSquare size={30}/>
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/tokkiro',
      image: <FaInstagramSquare size={30}/>

    },
    {
      name: 'Twitter',
      url: 'https://www.twitter.com/tokkiro',
      image: <FaSquareXTwitter size={30}/>

    },
  ]
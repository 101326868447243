import React from 'react'
import styles from './styles/NavMobile.module.scss';
import { Links } from '../assets/navigation/navigation';
import { IoClose } from 'react-icons/io5';
import Link from 'next/link';

export default function NavMobile({ overlayRef, setMenuMobile }) {
  return (
      <div className={styles.navMobile} ref={overlayRef}>
          <button 
              className={styles.closeMenuMobile} 
              onClick={() => setMenuMobile(false)}
          >
              <IoClose size={30} />
          </button>
          <ul className={styles.navMobile__list}>
              {Links.map((link, index) => (
                  <li className={styles.navMobile__item} key={index}>
                      <Link href={link.url} aria-label={`Vai a ${link.name}`} rel='preload'>{link.name}</Link>
                  </li>
              ))}
          </ul>
      </div>
  );
}

import {gql} from '@apollo/client';


export const GET_PRODUCTS_LATEST_BY_CATEGORY = gql`
  query getProductsLastestforCategory{
    getProductsLastestforCategory{
      id
      title
      image
        price
        category
        slug
        subDescription

    }
  }
`;

export const GET_PRODUCTBYSLUG = gql`
query getProductBySlug($slug: String!){
    getProductBySlug(slug: $slug){
        id
        title
        description
        subDescription
        image
        price
        category
        url 
    }
}
`


export const GET_DRESCRIPTIONBYPRODUCT = gql`
    query getDescriptionByProduct($product: ID!){
        getDescriptionByProduct(product: $product){
            id
            title
            description
            image
            url
        }

    }
`

export const GET_PRODUCTSBYCATEGORY = gql`
query getProductsByCategory($category: String!){
    getProductsByCategory(category: $category){
        id
        title
        subDescription
        category
        image
        createdAt
        updatedAt
        slug

    }
}
`


export const GET_PRODUCTSLASTESTBYCATEGORYTONUMBER = gql`
query  getProductsByLastCategoryToNumber($category: String!, $number: Int!){
    getProductsByLastCategoryToNumber(category: $category, number: $number){
        id
        title
        subDescription
        category
        image
        createdAt
        updatedAt
        slug
    }
}
`